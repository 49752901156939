import axios from 'axios'
import store from '@/store/index'
import { Message } from 'element-ui' //element库的消息提示，可以不用
import errorCode from '@/util/errorCode'

// 允许请求码的范围 请求码拦截
axios.defaults.validateStatus = function(status) {
    return status >= 200 && status <= 500
}

// 环境的切换
// if (process.env.NODE_ENV == 'development') { //开发
//     axios.defaults.baseURL = '/api';}
// else if (process.env.NODE_ENV == 'debug') { //测试
//     axios.defaults.baseURL = 'https://www.ceshi.com';
// }
// else if (process.env.NODE_ENV == 'production') { //线上
//     axios.defaults.baseURL = 'https://www.production.com';
// }

// 请求超时时间
axios.defaults.timeout = 15000

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.baseURL = 'https://www.fzygsz.com:5000/' // 资料管理

// 请求拦截器
axios.interceptors.request.use(
    config => {
        const token = 'token:c01db84048994e6487fde4ee93804774'
        if (token) {
            config.headers.Token = token
        }
        return config
    },
    error => {
        return Promise.error(error)
    }
)
// 响应拦截器
axios.interceptors.response.use(
    response => {
        const status = Number(response.status) || 200
        const message = errorCode[status] || errorCode['default']
        if (response.status === 200) {
            return response
        } else {
            return Message({ message: status + ' ' + message, type: 'error' })
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response.status) {
            console.log(error)
        }
        return Promise.reject(error.response)
    }
)
// get请求封装
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, { params: params })
            .then(res => resolve(res.data))
            // .catch(err => (err.response ? reject(err.data) : Message({ showClose: true, message: 'get请求错误', type: 'error' })))
            .catch(err => (err.response ? reject(err.data) : console.log(err)))
    })
}
// post请求封装
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then(res => resolve(res.data))
            // .catch(err => (err.response ? reject(err.data) : Message({ showClose: true, message: 'post请求错误', type: 'error' })))
            .catch(err => (err.response ? reject(err.data) : console.log(err)))
    })
}

export default axios
